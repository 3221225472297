import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/less/style.less'
import './assets/css/global.css'
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
import axios from 'axios'
import infiniteScroll from 'vue-infinite-scroll'

import Back from './utils/back'
Vue.use(infiniteScroll)
Vue.prototype.$axios = axios
Vue.use(element)
Vue.config.productionTip = false

//下面这句代码别删
import wordcloud from 'echarts-wordcloud'
//需要挂载到Vue原型上
new Vue({
    Back,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
