import Vue from 'vue'
// 按需引入：base.css 基础样式必须引入，其它组件样式按需引入
import "element-ui/packages/theme-chalk/lib/base.css";
import "element-ui/packages/theme-chalk/lib/collapse.css";
import "element-ui/packages/theme-chalk/lib/collapse-item.css";
import "element-ui/packages/theme-chalk/lib/select.css";
import "element-ui/packages/theme-chalk/lib/option.css";

import {Icon,TabPane,Tabs,Menu,Upload,Pagination,Progress,Card,Button,Image,Submenu,Tree,Dialog,Radio,Checkbox,RadioGroup,Popover,
    MenuItem,Drawer,DatePicker,Table,TableColumn,Form,FormItem,Loading,Select,Option,Message,MessageBox,Input} from 'element-ui';//从element-ui中引入按钮

const element = {
    install: function(Vue) {
        Vue.use(TabPane)
        Vue.use(Tabs)
        Vue.use(Menu)
        Vue.use(Submenu)
        Vue.use(Popover)
        Vue.use(MenuItem)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Table)
        Vue.use(Dialog)
        Vue.use(TableColumn)
        Vue.use(Tree)
        Vue.use(Loading)
        Vue.use(Form)
        Vue.use(Image)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(Checkbox)
        Vue.use(Card)
        Vue.use(Button)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(Upload)
        Vue.use(Drawer)
        Vue.use(DatePicker)
        Vue.use(Progress);
        Vue.use(Pagination)
        Vue.use(Icon)

        Vue.prototype.$message = Message;
        Vue.prototype.$confirm = MessageBox.confirm;
    }
}

export default element
