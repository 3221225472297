import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from "axios";
import Cookies from 'js-cookie'
import Keycloak from "keycloak-js";
import jwtDecode from 'jwt-decode'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: store.state.home,
    component: () => import('../views/companyJH/index'),
    children: [
      {
        path: '/overviewJH',
        name: 'overviewJH',
        component: () => import('../views/companyJH/children/overviewJH.vue'),
        meta:{
          isBack:false
        },
        // 本地测试注释/正式打开
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))
          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
          }
        }
      },
      {
        path: '/modelJH',
        name: 'modelJH',
        component: () => import('../views/companyJH/children/modelJH.vue'),
      },
      {
        path: '/reportLists',
        name: 'reportLists',
        component: () => import('../views/companyJH/children/reportLists.vue'),
        // 本地测试注释/正式打开
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
          }
        }
      },
      {
        path: '/reportListsDetail',
        name: 'reportListsDetail',
        component: () => import('../views/companyJH/children/reportListsDetail.vue'),
        // 本地测试注释/正式打开
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
          }
        }
      },
      {
        path: '/reportInfo',
        name: 'reportInfo',
        component: () => import('../views/companyJH/children/reportInfo.vue'),
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
          }
        }
      },
      {
        path: '/controlJH',
        name: 'controlJH',
        component: () => import('../views/controlJH/controlJH.vue'),

      },
      {
        path: '/noPermissions',
        name: 'noPermissions',
        component: () => import('../components/noPermissions.vue'),
        // beforeEnter: function(to, from, next) {
        //   sessionStorage.setItem("url",JSON.stringify(window.location.href))
        //   next();
        // }
      },
      {
        path: '/accessStatistics',
        name: 'accessStatistics',
        component: () => import('../views/accessStatistics/accessStatistics.vue'),
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
            console.log(from)
          }
        }
      },
      {
        path: '/accessDetaile',
        name: 'accessDetaile',
        component: () => import('../views/accessStatistics/accessDetaile.vue'),
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
            console.log(from)
          }
        }
      },
      {
        path: '/authorizeQRCodes',
        name: 'authorizeQRCodes',
        component: () => import('../components/authorizeQRCodes.vue'),
      },
      {
        path: '/LoginCodes',
        name: 'LoginCodes',
        component: () => import('../views/companyJH/children/LoginCodes.vue'),
        beforeEnter: function(to, from, next) {
          // sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          // if (sessionStorage.getItem("userInfo")) {
            next();
          // } else {
          //   next({
          //     path: '/LoginCodes',
          //     query: to.query
          //   });
          // }
        }
      },
      {
        path: '/wxWorkUserManagement',
        name: 'wxWorkUserManagement',
        component: () => import('../views/userMessage/wxWorkIndex.vue'),
        beforeEnter: function(to, from, next) {
          sessionStorage.setItem("url",JSON.stringify(window.location.href))
          sessionStorage.setItem("from",JSON.stringify(from.path))

          if (sessionStorage.getItem("userInfo")) {
            next();
          } else {
            next({
              path: '/LoginCodes',
              query: to.query
            });
          }
        },

      },
    ]
  },
//   {
//     path: '/accessStatistics',
//     name: 'accessStatistics',
//     component: () => import('../views/accessStatistics/accessStatistics.vue'),
//   }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: process.env.BASE_URL,
  routes
})
let source = axios.CancelToken.source();
const nologin = ['/overviewJH','/reportLists','/reportInfo','/noPermissions','/accessStatistics','/accessDetaile','/authorizeQRCodes','/LoginCodes','/wxWorkUserManagement','/reportListsDetail']
router.beforeEach(async (to, from, next) => {
  localStorage.setItem("login_go", from.name)
  source.cancel();
  source = axios.CancelToken.source();

  async function goLoginSys() {
    let keycloak = null;

    const refreshToken = Cookies.get('refreshToken')
    if (refreshToken) {
      store.commit('setHome','/overviewJH')
      if(!store.state.token ){
        await setToken()
      }
    } else {
      keycloak =  Keycloak({
        url: 'http://u.xlmediawatch.com/auth',
        realm: 'xlplatform',
        clientId: "inews-api"
      });

      keycloak.init({onLoad: 'login-required', checkLoginIframe: false}).success( (authenticated) =>{
        if (authenticated) {
            Cookies.set('refreshToken',keycloak["refreshToken"])
          if(!store.state.token){
             setToken()
          }else{
            const user = jwtDecode(store.state.token);
            store.commit("setUser",user)
          }
          //
          // keycloak.loadUserInfo().success(data => {
          //   // store.commit('setreFreshToken', keycloak["refreshToken"])
          //   // this.$store.commit('setHome','/overviewJH')
          // })
        }
      }).error(() => {
        console.log('登陆出错了！！')
      });
    }
  }
  async function setToken() {
    if (Cookies.get('refreshToken') && !store.state.token ) {
      if(!store.state.token){
        const client_id = window.location.hostname;
        const params = new URLSearchParams();
        params.append('grant_type', "refresh_token");
        params.append('client_id', "inews-api");
        params.append('refresh_token', Cookies.get('refreshToken'));
        // params.append('refresh_token', 'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI1Y2NlNzJlYi1jOGJlLTQ3YTktYWZhZi1iMjhlMTEwNTJjNzgifQ.eyJqdGkiOiJjOTNiZWU0Mi05MGU0LTQ4ZGQtODNiOS02N2E1Y2VmYzBiYzEiLCJleHAiOjE2NTE0NjM2NzYsIm5iZiI6MCwiaWF0IjoxNjUwODcwNTkyLCJpc3MiOiJodHRwOi8vdS54bG1lZGlhd2F0Y2guY29tL2F1dGgvcmVhbG1zL3hscGxhdGZvcm0iLCJhdWQiOiJodHRwOi8vdS54bG1lZGlhd2F0Y2guY29tL2F1dGgvcmVhbG1zL3hscGxhdGZvcm0iLCJzdWIiOiJkYzQxNjczMC1hZjdiLTQzOWEtODI0My05MDJlODc5MzZlZGEiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoiaW5ld3MtYXBpIiwibm9uY2UiOiIzNTE4Yzc4ZC0xMDQ1LTQ5NTEtYmUwZC0wZjE5OGZmNDZkNGUiLCJhdXRoX3RpbWUiOjAsInNlc3Npb25fc3RhdGUiOiIyMTAyYTg4Zi1mZjIzLTRiYTMtYjlhMC1iMTUzNmE2ZGUxZjAiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImxvY2FsaG9zdCI6eyJyb2xlcyI6WyJtb25pdG9yX21vZHVsZSIsImNsb3VkX21vZHVsZSIsImNyaXNpc19tb2R1bGUiLCJwcm9jZXNzX21vZHVsZSJdfSwiaWRhdGFfc2VydmUiOnsicm9sZXMiOlsiaW5kdXN0cnlfZGF0YV9lZGl0IiwiYWN0aXZpdHlfZGF0YV9lZGl0Il19LCJwcm9jZXNzLnhsbWVkaWF3YXRjaC5jb20iOnsicm9sZXMiOlsiY2xvdWRfbW9kdWxlIiwiY3Jpc2lzX21vZHVsZSIsImFuYWx5c2lzX21vZHVsZSIsInByb2Nlc3NfbW9kdWxlIl19LCJ5cS5pbmV3c2RhdGEuY29tIjp7InJvbGVzIjpbIm1vbml0b3JfbW9kdWxlIiwiY2xvdWRfbW9kdWxlIiwiYW5hbHlzaXNfbW9kdWxlIl19LCJpbmV3czUueGxtZWRpYXdhdGNoLmNvbSI6eyJyb2xlcyI6WyJtb25pdG9yX21vZHVsZSIsImNsb3VkX21vZHVsZSIsImFuYWx5c2lzX21vZHVsZSIsImNyaXNpc19tb2R1bGUiXX0sInFpbmdoYWkuaW5ld3NkYXRhLmNvbSI6eyJyb2xlcyI6WyJtb25pdG9yX21vZHVsZSIsImNsb3VkX21vZHVsZSIsImFuYWx5c2lzX21vZHVsZSJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZ3JvdXBzIHByb2ZpbGUgZW1haWwgcGhvbmVBdHRyIn0.aQ8WuwMheJVryNZvXCeZDxncTikbjdcINiSGNpIgRvY');
        console.log(params)
        const res = await axios.post('http://u.xlmediawatch.com/auth/realms/xlplatform/protocol/openid-connect/token',
            params,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
        if(res.status == 200){
          store.commit("setToken",res.data['access_token'])
          const user = jwtDecode(res.data['access_token']);
          user.name = user.name + '(控制台)'
          store.commit("setUser",user)
        }else{
          store.commit("setToken","")
          store.commit("setUser","")
        }
        if(to.path == '/' || to.path == ''){
          next('/controlJH')
        }else{
          next();
        }
      }
    }else{
      if(to.path == '/' || to.path == ''){
        next('/controlJH')
      }else{
        next();
      }
    }
  }

  if(to.path == '/' || to.path == '' || (nologin.join(",").indexOf(to.path) != -1 )){
    if(to.path == '/' || to.path == ''){
      next('/controlJH')
    }else{
      next()
    }
  }else{
    const refreshToken =  Cookies.get('refreshToken')
    if(!refreshToken){
      await goLoginSys()
    }else{
      await setToken()
    }
  }
})



export default router
