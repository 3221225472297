<template>
  <div id="app">
    <transition>
      <router-view/>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: 15528824744,
      password: 123456
    }
  },
  async created() {

    window.addEventListener('pageshow', function(event) {
      if(JSON.parse(sessionStorage.getItem("num")) == 2 && window.location.pathname == "/LoginCodes"){
        // alert(JSON.parse(sessionStorage.getItem("num")))
        wx.closeWindow()
      }
    });
    await this.isWxBrowser()
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      // alert('移动端');  // 移动端
      this.$store.commit('changeIsPhone',{isPhone:true})
    }else{
      // alert('PC端');  // PC端
      this.$store.commit('changeIsPhone',{isPhone:false})
    }
    const orgId = sessionStorage.getItem('orgId')
    if (orgId) {
      this.$store.commit('setOrgId', {orgId: orgId})
    }
  },
  methods: {
    async isWxBrowser() {
      var ua = navigator.userAgent.toLowerCase();
      let isWxWork = ua.match(/WxWork/i) == "wxwork";
      this.$store.commit('changeIsWxWork',{isWxWork:isWxWork})
    },
  }
}
</script>
<style lang="less">
#app {
  width: 100%;
  /*height: 100%;*/
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
