import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    orgId:"",
    isPhone:false,
    refreshToken:"",
    home:"",
    isWxWork:false,
    noLogin : ['/overviewJH','/reportLists','/reportInfo','/noPermissions','/accessStatistics'],
    user:""
  },
  mutations: {
    setToken(state,token){
      state.token = token
    },
    setHome(state,home){
      state.home = home
    },
    setreFreshToken(state,refreshToken){
      state.refreshToken = refreshToken
    },
    setOrgId(state,payload){
      state.orgId = payload.orgId
    },
    setUser(state,user){
      state.user = user
    },
    changeIsPhone(state,payload){
      state.isPhone = payload.isPhone
    },
    changeIsWxWork(state,payload){
      state.isWxWork = payload.isWxWork
    },

  },
  actions: {
  },
  modules: {
  },
  getters:{
    getToken:state=>{
      if(state.token){
        return state.token
      }else{
        state.token = Vue.syncHttpSendToken
        return state.token
      }
    }
  }
})
